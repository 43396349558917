<template>
    <b-row>
        <b-col>
            <b-card>
                <b-card-body>
                    <validation-observer
                        ref="infoRules"
                        tag="form"
                        >   
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    label="Nama Bisnis"
                                    label-for="bisnis-name"
                                >
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Nama Bisnis"
                                    rules="required"
                                    :custom-messages="customMessages"
                                    >
                                    <b-form-input
                                        id="bisnis-name"
                                        v-model="companyName"
                                        placeholder="Masukan Nama Bisnis"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Website Bisnis (Optional)"
                                    label-for="website-business-name"
                                >
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Website Bisnis"
                                    rules="url"
                                    :custom-messages="customMessages"
                                    >
                                    <b-form-input
                                        id="website-business-name"
                                        v-model="companySite"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Masukan Website Bisnis"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Tipe"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Tipe"
                                    label-for="tipe"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <v-select
                                        id="tipe"
                                        v-model="ownershipTypeID"
                                        :options="ownership_type"
                                        placeholder="Pilih Tipe Kepemilikan Usaha"
                                        label="name"
                                    >
                                        <span slot="no-options">
                                            Data tidak ditemukan
                                        </span>
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="placeholderOwnerType"
                                    :rules="
                                    ownershipTypeID ? ( ownershipTypeID.id == 2 ? 'required' : '' ) : ''
                                    "
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    :label="placeholderOwnerType"
                                    label-for="npwp"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <b-form-input
                                        id="npwp"
                                        v-model="npwp"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="'Masukan ' + placeholderOwnerType"
                                        v-mask="'##.###.###.#-###.###'"
                                    />
                                    <b-form-invalid-feedback :state="errNpwp.length > 0 ? false:null">{{ errNpwp }}</b-form-invalid-feedback>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Provinsi"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Provinsi"
                                    label-for="provinsi"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <v-select
                                        id="provinsi"
                                        v-model="province"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="list_province"
                                        placeholder="Pilih Provinsi"
                                        label="name"
                                    >
                                        <span slot="no-options">
                                            Data tidak ditemukan
                                        </span>
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Kabupaten"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Kota/Kabupaten"
                                    label-for="kabupaten"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <v-select
                                        id="kabupaten"
                                        v-model="city"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="list_city"
                                        placeholder="Pilih Kabupaten / Kota"
                                        label="name"
                                    >
                                        <span slot="no-options">
                                            Data tidak ditemukan
                                        </span>
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Alamat"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Alamat"
                                    label-for="alamat"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <b-form-textarea
                                        id="alamat"
                                        placeholder="Masukkan Alamat"
                                        rows="8"
                                        v-model="address"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Jenis Usaha"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Jenis Usaha"
                                    label-for="jenis-usaha"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <v-select
                                        id="jenis-usaha"
                                        v-model="companyTypeID"
                                        v-on:input="changeTypeID()"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="list_company_type"
                                        placeholder="Pilih Jenis Usaha"
                                        label="name"
                                    >
                                        <span slot="no-options">
                                            Data tidak ditemukan
                                        </span>
                                    </v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Nama Jenis Usaha"
                                    rules="required"
                                    :custom-messages="customMessages"
                                >
                                    <b-form-group
                                    label="Nama Jenis Usaha"
                                    label-for="nama-jenis-usaha"
                                    :state="errors.length > 0 ? false:null"
                                    >
                                    <v-select
                                        v-if="!companyTypeNameFreeText"
                                        id="nama-jenis-usaha"
                                        v-model="companyTypeNameID"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="list_company_subtype"
                                        placeholder="Pilih Nama Jenis Usaha"
                                        label="name"
                                    >
                                        <span slot="no-options">
                                            Data tidak ditemukan
                                        </span>
                                    </v-select>
                                    <b-form-input
                                        v-if="companyTypeNameFreeText"
                                        id="nama-jenis-usaha"
                                        v-model="companyTypeNameID"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Masukan Nama Jenis Usaha"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="4">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="validationFormBusiness()"
                                    >
                                    Simpan
                                </b-button>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormDatepicker,
  BFormTextarea,
  BButton,
  VBModal,
  BModal,
  BCardText,
  BLink,
  BInputGroup,
  BForm,
  BCard,
  BCardBody
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {mask} from 'vue-the-mask'
import axios from '@axios'
import eventBus from '@/libs/eventBus'

export default {
    name: 'BusinessInformation',
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormRadio,
        BFormDatepicker,
        BFormTextarea,
        BButton,
        VBModal,
        BModal,
        BCardText,
        BLink,
        BInputGroup,
        BForm,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BCard,
        BCardBody
    },
    directives: {
        mask
    },
    data() {
        return {
            customMessages: {
                required: 'Harus diisi',
                url: 'Alamat website tidak valid'
            },
            placeholderOwnerType: 'NPWP',
            companyName: null,
            companySite: null,
            ownershipTypeID: null,
            npwp: null,
            province: null,
            city: null,
            address: null,
            companyTypeID: null,
            companyTypeNameID: null,

            placeholderOwnerType: 'NPWP',
            companyTypeNameFreeText: false,

            errNpwp: '',
            required,
            email,

            ownership_type: [],
            list_province: [],
            list_city: [],
            list_company_type: [],
            list_company_subtype: [],
        }
    },
    beforeMount(){
        let _ = this
        _.getOwnershipType()
        _.getProvince()
        _.getCompanyType()
    },
    mounted() {
        let _ = this
        
        _.getInfoBusiness()

        eventBus.$on('UPDATE_BUSINESS_INFO', ()=>{
            this.updateBusinessInfo()
        })
    },
    methods: {
        changeTypeID()
        {
            // _.getSubType(companyTypeID)
            console.log("Hallo");
            let _ = this
            _.companyTypeNameID = null

        },
        getInfoBusiness() {
            let _ = this
            let userData = JSON.parse(localStorage.getItem('userData')).advertiser
            _.companyName = userData.companyName
            _.companySite = userData.companySite
            _.npwp = userData.npwp
            _.province = userData.province
            _.city = userData.city
            _.address = userData.address
            _.companyTypeID = userData.companyTypeID
            _.companyTypeNameID = userData.companyTypeNameID
        },
        getOwnershipType() {
            let _ = this
            axios.get('/misc/types/ownership-types')
            .then(resp => {
                _.ownership_type = resp.data.data
            })
            .catch(err => {
                console.log(err.response)
            })
        },
        getProvince() {
            let _ = this
            axios.get('/misc/types/provinces')
            .then(resp => {
                _.list_province = resp.data.data
            })
            .catch(err => {
                console.log(err.response)
            })
        },
        getCity(provinceID) {
            let _ = this
            
            axios.get('/misc/types/city/' + provinceID)
                .then(resp => {
                    _.list_city = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getCompanyType() {
            let _ = this

            axios.get('misc/types/company-types')
                .then(resp => {
                    _.list_company_type = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getSubType(companyTypeID) {
            let _ = this
            axios.get('misc/types/company-type-names/' + companyTypeID)
                .then(resp => {
                    _.list_company_subtype = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        sanitizeNPWP() {
            let _ = this
            let npwp;
            if (this.npwp != null) {
                npwp = _.npwp.replaceAll('.','')
                npwp = npwp.replaceAll('-','')    
            }else{
                npwp = null
            }            

            return npwp
        },

        validationFormBusiness() {
            let _ = this
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    axios.put('/advertiser/register/business-info', {
                            companyName: _.companyName,
                            companySite: _.companySite,
                            ownershipTypeID: _.ownershipTypeID.id,
                            npwp: _.sanitizeNPWP(),
                            province: _.province.name,
                            city: _.city.name,
                            address: _.address,
                            companyTypeID: _.companyTypeID.id,
                            companyTypeNameID: _.companyTypeNameFreeText ? _.companyTypeNameID : _.companyTypeNameID.id
                        })
                        .then(res => {
                            let _ = this
                            let userData = JSON.parse(localStorage.getItem('userData'))
                            userData = {
                                ...userData,
                                advertiser: {
                                    ...userData.advertiser,
                                    companyName: _.companyName,
                                    companySite: _.companySite,
                                    ownershipTypeID: _.ownershipTypeID.id,
                                    npwp: _.sanitizeNPWP(),
                                    province: _.province.name,
                                    city: _.city.name,
                                    address: _.address,
                                    companyTypeID: _.companyTypeID.id,
                                    companyTypeNameID: _.companyTypeNameFreeText ? _.companyTypeNameID : _.companyTypeNameID.id
                                }
                            }
                            localStorage.setItem('userData', JSON.stringify(userData))
                            _.$bvToast.toast('Perubahan Berhasil Disimpan', {
                                title: "Sukses",
                                variant: "success",
                                solid: true,
                            })
                        })
                        .catch(err => {
                            let  errors = err.response.data.message.errors
                            if ( errors.length > 0 ) {
                                errors.forEach(er => {
                                    if(er.param == 'npwp') {
                                        _.errNpwp = er.msg
                                    }
                                })
                                
                            }
                            reject()
                        })
                } else {
                    console.log('failed validation')
                }
            })
        }
    },
    watch: {
      'province': function(newValue, oldValue) {
          let _ = this
          _.city = null
          if ( oldValue ) {
                if ( oldValue.id != newValue.id ) {
                    _.getCity(newValue.id)
                }
          } else {
              _.getCity(newValue.id)
          }
      },
      'companyTypeID': function(newValue, oldValue) {
          let _ = this
          console.log('watch companyTypeID')
          console.log('newValue : ' + newValue)
          console.log('oldValue : ' + oldValue)
          _.companyTypeNameFreeText = newValue.name == 'Lain-Lain'
        //   _.companyTypeNameID = null
          if ( oldValue ) {
                if ( oldValue.id != newValue.id ) {
                    // _.companyTypeNameFreeText = newValue.name == 'Lain-Lain'
                    _.getSubType(newValue.id)
                }
          } else {
              _.getSubType(newValue.id)
          }
          
      },
      'ownership_type': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.ownershipTypeID = newValue.filter(ot => {
                return ot.id == userData.ownershipTypeID
          })[0]
      },
      'list_company_type': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.companyTypeID = newValue.filter(ot => {
                return ot.id == userData.companyTypeID
          })[0]
          _.getSubType(userData.companyTypeID)
      },
      'list_company_subtype': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          console.log('watch list_company_subtype')
          console.log(userData)
          console.log(newValue)
          if ( userData ) {
              if ( userData.companyTypeNameID ) {
                  let typeName = newValue.filter(ot => {
                    return ot.id == userData.companyTypeNameID
                  })[0]

                  _.companyTypeNameID = _.companyTypeID.name == 'Lain-Lain' ? typeName.name : typeName
              }
          }
      },
      'list_province': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          console.log('WATCH')
          console.log(userData)
          _.province = newValue.filter(ot => {
                return ot.name == userData.province
          })[0]
          _.getCity( _.province.id)
      },
      'list_city': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.city = newValue.filter(ot => {
                return ot.name == userData.city
          })[0]
      },
      'ownershipTypeID': function(newValue, oldValue) {
          if ( newValue ) {
              this.placeholderOwnerType = 'NPWP ' + newValue.name
              if ( oldValue !== null ) {
                  this.npwp = null
              }
          }
      }
  }
}
</script>