var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',[_c('b-card-body',[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Bisnis","label-for":"bisnis-name"}},[_c('validation-provider',{attrs:{"name":"Nama Bisnis","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bisnis-name","placeholder":"Masukan Nama Bisnis","state":errors.length > 0 ? false:null},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Website Bisnis (Optional)","label-for":"website-business-name"}},[_c('validation-provider',{attrs:{"name":"Website Bisnis","rules":"url","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"website-business-name","state":errors.length > 0 ? false:null,"placeholder":"Masukan Website Bisnis"},model:{value:(_vm.companySite),callback:function ($$v) {_vm.companySite=$$v},expression:"companySite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tipe","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipe","label-for":"tipe","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"tipe","options":_vm.ownership_type,"placeholder":"Pilih Tipe Kepemilikan Usaha","label":"name"},model:{value:(_vm.ownershipTypeID),callback:function ($$v) {_vm.ownershipTypeID=$$v},expression:"ownershipTypeID"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Data tidak ditemukan ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.placeholderOwnerType,"rules":_vm.ownershipTypeID ? ( _vm.ownershipTypeID.id == 2 ? 'required' : '' ) : '',"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.placeholderOwnerType,"label-for":"npwp","state":errors.length > 0 ? false:null}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###.#-###.###'),expression:"'##.###.###.#-###.###'"}],attrs:{"id":"npwp","state":errors.length > 0 ? false:null,"placeholder":'Masukan ' + _vm.placeholderOwnerType},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.errNpwp.length > 0 ? false:null}},[_vm._v(_vm._s(_vm.errNpwp))]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"provinsi","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"provinsi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.list_province,"placeholder":"Pilih Provinsi","label":"name"},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Data tidak ditemukan ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Kabupaten","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kota/Kabupaten","label-for":"kabupaten","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"kabupaten","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.list_city,"placeholder":"Pilih Kabupaten / Kota","label":"name"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Data tidak ditemukan ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Alamat","label-for":"alamat","state":errors.length > 0 ? false:null}},[_c('b-form-textarea',{attrs:{"id":"alamat","placeholder":"Masukkan Alamat","rows":"8"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Jenis Usaha","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Usaha","label-for":"jenis-usaha","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"jenis-usaha","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.list_company_type,"placeholder":"Pilih Jenis Usaha","label":"name"},on:{"input":function($event){return _vm.changeTypeID()}},model:{value:(_vm.companyTypeID),callback:function ($$v) {_vm.companyTypeID=$$v},expression:"companyTypeID"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Data tidak ditemukan ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Jenis Usaha","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama Jenis Usaha","label-for":"nama-jenis-usaha","state":errors.length > 0 ? false:null}},[(!_vm.companyTypeNameFreeText)?_c('v-select',{attrs:{"id":"nama-jenis-usaha","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.list_company_subtype,"placeholder":"Pilih Nama Jenis Usaha","label":"name"},model:{value:(_vm.companyTypeNameID),callback:function ($$v) {_vm.companyTypeNameID=$$v},expression:"companyTypeNameID"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Data tidak ditemukan ")])]):_vm._e(),(_vm.companyTypeNameFreeText)?_c('b-form-input',{attrs:{"id":"nama-jenis-usaha","state":errors.length > 0 ? false:null,"placeholder":"Masukan Nama Jenis Usaha"},model:{value:(_vm.companyTypeNameID),callback:function ($$v) {_vm.companyTypeNameID=$$v},expression:"companyTypeNameID"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validationFormBusiness()}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }